


class HumanPlayer {

    constructor() {
        this.type = "human";
    }

    makeMove(board, view) {
        // debugger;
        view.addEventHandler();
        // let moves = board.availableMoves();
        // board.makeMove(moves[Math.floor(Math.random() * moves.length)]);
        // view.refreshBoard(board.getBoard(), board.availableMoves());
        // console.log("hi");
        // debugger;
        // if (!board.gameOver()) {
        //     this.play();
        // }
    }
}

export default HumanPlayer;



// make the onclick function in view call on game play